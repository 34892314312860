@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.MuiTabs-indicator {
    display: none;
}

.ck.ck-button.category-label.ck-disabled.ck-off.ck-button_with-text {
    font-weight: bold;
    background-color: #c1c1c1;
    pointer-events: none;
    cursor: default;
    padding: 5px;
    color: rgb(0 0 0);
    border: 1px solid #c1c1c1;
}

.scrollable-dropdown .ck-list {
    max-height: 200px;
    overflow-y: auto;
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se {
    overflow: auto;
    max-height: 400px;
}

.user-variables-button {
    display: flex;
}
.rdrWeekDay {
    display: inline-block; /* Para que a largura seja aplicada corretamente */
    width: 3ch;           /* Largura suficiente para 3 caracteres */
    overflow: hidden;     /* Oculta qualquer texto que exceda a largura */
    white-space: nowrap;  /* Impede a quebra de linha */
    text-overflow: clip;  /* Não adiciona reticências */
}

/* Outros estilos que você já possui */

/* cor quando passa o mouse por cima */
.MuiButtonBase-root.buttonHover:hover {
    background: rgb(36, 36, 36);
}

/* cor enquanto não passa o mouse */
.MuiButtonBase-root.buttonHover {
    background: rgb(71, 71, 71);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}

.wave {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
}

.img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.img img {
    width: 500px;
}

.login-content img {
    height: 65px;
}

.login-content h2 {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.login-content .input-div {
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #3287c9;
}

.login-content .input-div.one {
    margin-top: 0;
}

.i {
    color: #3287c9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.i i {
    transition: 0.3s;
}

.input-div > div {
    position: relative;
    height: 45px;
}

.input-div > div > h5 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
}

.input-div:before,
.input-div:after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #3287c9;
    transition: 0.4s;
}

.input-div:before {
    right: 50%;
}

.input-div:after {
    left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
    width: 50%;
}

.input-div.focus > div > h5 {
    top: -5px;
    font-size: 15px;
}

.input-div.focus > .i > i {
    color: #3287c9;
}

.input-div > div > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
    font-family: 'poppins', sans-serif;
}

.input-div.pass {
    margin-bottom: 4px;
}

/* a{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
} */

a:hover {
    color: #3287c9;
}

.btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #3287c9, #3287c9, #3287c9);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: 0.5s;
}
.btn:hover {
    background-position: right;
}

@media screen and (max-width: 1050px) {
    .container {
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 1000px) {
    form {
        width: 290px;
    }

    .login-content h2 {
        font-size: 2.4rem;
        margin: 8px 0;
    }

    .img img {
        width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .container {
        grid-template-columns: 1fr;
    }

    .img {
        display: none;
    }

    .wave {
        display: none;
    }

    .login-content {
        justify-content: center;
    }
}

@media print {
    body {
        margin: 0 !important;
    }
}

.editor-main-container {
    --ckeditor5-preview-height: 70dvh;
    display: flex;
    font-family: 'Lato';
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.ck-content {
    font-family: 'Lato';
    line-height: 1.6;
    word-break: break-word;
}

.editor-container__editor-wrapper {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.editor-container_document-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    height: 40px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    background: var(--ck-color-base-foreground);
}

.editor-container_document-editor .editor-container__editor {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 100%;
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: calc(210mm + 2px);
    max-width: calc(210mm + 2px);
    min-height: 297mm;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10mm 20mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: 72px;
    margin-right: 72px;
}

