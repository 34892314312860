.MuiTabs-indicator {
    display: none;
}

.ck.ck-button.category-label.ck-disabled.ck-off.ck-button_with-text {
    font-weight: bold;
    background-color: #c1c1c1;
    pointer-events: none;
    cursor: default;
    padding: 5px;
    color: rgb(0 0 0);
    border: 1px solid #c1c1c1;
}

.scrollable-dropdown .ck-list {
    max-height: 200px;
    overflow-y: auto;
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se {
    overflow: auto;
    max-height: 400px;
}

.user-variables-button {
    display: flex;
}